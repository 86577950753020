import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { concat, map, omit, min, max } from 'lodash';
import { scaleLinear } from 'd3-scale';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Dot from './index';
import RaceSelect from '../../page/raceSelect';
import StateMenu from '../../page/stateMenu';
import { races } from '../../util/race';

import './dot.css';

const DotChart = ({ state, indicatorKey }) => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allDomains(orderBy: "order") {
            indicator(orderBy: "name") {
              key
              name
              goal
              format
              values {
                aian: aian_rate
                aspi: aspi_rate
                black: black_rate
                hisp: hisp_rate
                multi: multi_rate
                white: white_rate
                state {
                  abbreviation
                }
              }
            }
          }
          allStates {
            abbreviation
            name
          }
        }
      }
    `}
    render={data => {
      const indicators = data.hope.allDomains.reduce(
        (memo, d) =>
          concat(
            memo,
            d.indicator.map(i => omit(i, 'national'))
          ),
        []
      );

      const [indicator, setIndicator] = useState(indicators[0]);
      const [activeRaces, setActiveRaces] = useState(map(races, 'key'));
      const [activeStates, setActiveStates] = useState([state]);

      useEffect(() => {
        setIndicator(indicators.find(i => i.key === indicatorKey));
      }, [indicatorKey]);

      const recalculateScale = () => {
        const { values } = indicator;
        const goal = parseFloat(indicator.goal);
        const minValue = [...values, goal].reduce(
          (m, v) => min([...Object.values(omit(v, 'state')), m]),
          Infinity
        );
        const maxValue = [...values, goal].reduce(
          (m, v) => max([...Object.values(omit(v, 'state')), m]),
          -Infinity
        );
        const newScale = scaleLinear().domain([minValue, maxValue]).nice().range([0, 100]);
        return newScale;
      };

      return (
        <Row>
          <Col sm={4}>
            <h3>State Distance to Goal</h3>
            <h4>{indicator.name}</h4>
            <p style={{ paddingRight: 20 }}>
              <span>This chart is interactive. </span>
              <b>
                Explore data by clicking the dots to select and compare different race and ethnicity
                groups.
              </b>
            </p>
            <RaceSelect handler={setActiveRaces} />
          </Col>
          <Col>
            {activeStates.map((st, i) => {
              const value = indicator.values.find(v => v.state.abbreviation === st);
              return (
                <div key={st} className="dotChart">
                  {i === 0 ? (
                    <h3>{data.hope.allStates.find(s => s.abbreviation === st).name}</h3>
                  ) : (
                    <StateMenu
                      handler={newState => {
                        const newStates = [...activeStates];
                        newStates[i] = newState.abbreviation;
                        setActiveStates(newStates);
                      }}
                    />
                  )}
                  <div className="dots">
                    {value && (
                      <Dot
                        values={[value]}
                        goal={
                          indicator.goal.match(/^\d+\.?\d*$/) ? parseFloat(indicator.goal) : null
                        }
                        narrow
                        numberFormat={indicator.format}
                        globalScale={recalculateScale()}
                        statesProp={[value.state.abbreviation]}
                        racesProp={activeRaces}
                      />
                    )}
                  </div>
                  <Button
                    className="compareAdditional"
                    variant="outline-dark"
                    onClick={() => setActiveStates([...activeStates, null])}
                  >
                    Compare Additional States
                  </Button>
                </div>
              );
            })}
          </Col>
        </Row>
      );
    }}
  />
);

DotChart.propTypes = {
  state: PropTypes.string.isRequired,
  indicatorKey: PropTypes.string.isRequired,
};

export default DotChart;
