import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const StateMenu = ({ handler }) => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allStates(orderBy: "name") {
            name
            abbreviation
          }
        }
      }
    `}
    render={data => (
      <DropdownButton className="domainMenu" title="Change State">
        {data.hope.allStates.map(state => (
          <Dropdown.Item key={state.name} as={Button}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => handler(state)}
              onKeyPress={() => handler(state)}
            >
              {state.name}
            </div>
          </Dropdown.Item>
        ))}
      </DropdownButton>
    )}
  />
);

StateMenu.propTypes = {
  handler: PropTypes.func.isRequired,
};

export default StateMenu;
