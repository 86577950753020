import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { findIndex, map } from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

import Summary from '../components/graphics/summary';
import Layout from '../components/layout';
import SectionButtons from '../components/page/sectionButtons';
import StateMenu from '../components/page/stateMenu';
import IndicatorMenu from '../components/page/indicatorMenu';
import StateDot from '../components/graphics/dot/StateDot';
import Gauge from '../components/graphics/gauge';
import { ordinal } from '../components/util/ordinal';

import '../styles/state.css';

export const query = graphql`
  query StatePage($id: ID!) {
    hope {
      State(where: { id: $id }) {
        id
        abbreviation
        name
        summary
        footnote
        finding
        values {
          natlrate: state_rate
          dtg: dtgnum
          dtgquin
          eqquin
          indicator {
            key
          }
        }
      }
      allIndicators {
        key
        name
        goal
        format
        disclaimer: summaryDisclaimer
        natltxt
        goaltxt
        dtgtxt
        domain {
          key
          color
        }
      }
      allDomains(orderBy: "order") {
        name
        color
        score(sortBy: score_DESC) {
          score
          state {
            id
          }
        }
        indicator(orderBy: "name", first: 1) {
          key
        }
      }
    }
  }
`;

export default function State({ data }) {
  const {
    hope: {
      State: { id, abbreviation, name, summary, values, finding, footnote },
      allDomains,
      allIndicators,
    },
  } = data;

  const [indicator, setIndicator] = useState(allDomains[0].indicator[0].key);
  const [indicatorValues, setIndicatorValues] = useState(
    values.find(v => v.indicator.key === indicator)
  );
  const [indicatorMeta, setIndicatorMeta] = useState(allIndicators.find(i => i.key === indicator));
  useEffect(() => {
    setIndicatorValues(values.find(v => v.indicator.key === indicator));
    setIndicatorMeta(allIndicators.find(i => i.key === indicator));
  }, [indicator]);

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <h1>HOPE Data by State</h1>
            <SectionButtons />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{name}</h2>
          </Col>
          <Col>
            <StateMenu
              handler={state =>
                navigate(`/state/${state.name.replace(/(\W+)/gm, '-').toLowerCase()}`)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{`How does ${name} Perform on Health Opportunity and Equity?`}</h3>
            <p>{`This graphic illustrates how ${name} compares to other states across various factors that influence health`}</p>
          </Col>
        </Row>
        <Row className="domainGrid" style={{ margin: '0 15px' }}>
          {allDomains.map(domain => {
            const domainScore = domain.score.find(state => state.state.id === id).score;
            const rank = findIndex(map(domain.score, 'score'), score => score === domainScore);
            return (
              <Col sm={12} md key={domain.name} style={{ backgroundColor: domain.color }}>
                <h4>{domain.name}</h4>
                <h1>{ordinal(rank + 1)}</h1>
                <hr style={{ marginTop: -20, marginBottom: 10 }} />
                <p>{`Domain score: ${domainScore}`}</p>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col lg={3}>
            <h3>Key State Highlights</h3>
          </Col>
          <Col>
            <p style={{ marginTop: 20 }}>{summary && parse(summary)}</p>
            <div>{finding && parse(finding)}</div>
            {footnote && <div className="footnote">{`* ${parse(footnote)}`}</div>}
          </Col>
        </Row>
        <hr style={{ marginLeft: 15, marginRight: 15 }} />
        <Row>
          <Col md={4}>
            <h3 style={{ color: indicatorMeta.domain.color }}>{indicatorMeta.name}</h3>
          </Col>
          <Col style={{ paddingTop: 10 }}>
            <IndicatorMenu handler={ind => setIndicator(ind.key)} />
          </Col>
        </Row>
        <Summary
          data={{ ...indicatorValues, ...indicatorMeta }}
          numberFormat={indicatorMeta.format}
          disclaimer={indicatorMeta.disclaimer}
          geography="State"
          state={name}
        />
        <hr style={{ marginLeft: 15, marginRight: 15 }} />
        <Row className="speedometer">
          <Col style={{ textAlign: 'left' }} md={12} lg>
            <h3>{`How Does ${name} Compare to Other States?`}</h3>
          </Col>
          <Col sm={12} md>
            <Gauge
              title="Racial Inequity"
              value={indicatorValues ? indicatorValues.eqquin : null}
              domain={indicatorMeta.domain.key}
              labels={['Higher', 'Lower']}
            />
          </Col>
          <Col sm={12} md>
            <Gauge
              title="Distance to Goal"
              value={indicatorValues ? indicatorValues.dtgquin : null}
              domain={indicatorMeta.domain.key}
              labels={['Further', 'Closer']}
            />
          </Col>
        </Row>
      </Container>
      {(indicatorValues.dtg !== null ||
        indicatorValues.dtgquin !== null ||
        indicatorValues.eqquin !== null) && (
        <Container>
          <StateDot state={abbreviation} indicatorKey={indicator} />
        </Container>
      )}
    </Layout>
  );
}

State.propTypes = {
  data: PropTypes.shape().isRequired,
};
