import React from 'react';
import PropTypes from 'prop-types';

import Speedometer from '../../../images/speedometer.svg';

import './gauge.css';

const Gauge = ({ value, title, domain, labels }) => (
  <div className={`value${value} domain-${domain}`}>
    <Speedometer style={{ height: 100 }} />
    <div className="guage-legend">
      <span>{labels[0]}</span>
      <span>{labels[1]}</span>
    </div>
    <h4>{title}</h4>
  </div>
);

Gauge.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  domain: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Gauge.defaultProps = {
  title: null,
  value: 999,
};

export default Gauge;
